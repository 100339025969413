import React, { useContext } from 'react'
import { matchPath, Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { RestaurantContext } from './providers/RestaurantProvider'
import useRestaurantCodes from './hooks/useRestaurantCodes'
import RestaurantNotFound from './components/Feedback/RestaurantNotFound'
import PrintModeProvider from './providers/PrintModeProvider'

const PageNotFound = React.lazy(() => import('./components/Feedback/PageNotFound'))
const Dashboard = React.lazy(() => import('./features/Dashboard/Dashboard'))
const CrashTaste = React.lazy(() => import('./features/CrashTaste/Vote/Vote'))
const CrashTasteSelectRestaurant = React.lazy(() => import('./features/CrashTaste/SelectRestaurant/SelectRestaurant'))
const CrashTasteReview = React.lazy(() => import('./features/CrashTaste/Vote/VoteReview'))
const CrashTasteOutlet = React.lazy(() => import('./features/CrashTaste/common/providers/CrashTasteOutlet'))
const CrashTasteKnowledge = React.lazy(() => import('./features/CrashTaste/Vote/VoteKnowledge'))
const CrashTasteMotivation = React.lazy(() => import('./features/CrashTaste/Vote/VoteMotivation'))
const CrashTasteThankYou = React.lazy(() => import('./features/CrashTaste/Vote/VoteThankYou'))
const BlindTestOutlet = React.lazy(() => import('./features/BlindTest/providers/BlindTestOutlet'))
const BlindTest = React.lazy(() => import('./features/BlindTest/BlindTest'))
const BlindTestJoin = React.lazy(() => import('./features/BlindTest/BlindTestJoin'))
const BlindTestRegister = React.lazy(() => import('./features/BlindTest/BlindTestRegister'))
const BlindTestTv = React.lazy(() => import('./features/BlindTest/TvBlindTest'))
const MusicLab = React.lazy(() => import('./features/MusicLab/MusicLab'))
const MusicLabOutlet = React.lazy(() => import('./features/MusicLab/providers/MusicLabOutlet'))
const MenuDigital = React.lazy(() => import('./features/MenuDigital/MenuDigital'))

function App() {
    const location = useLocation()
    const { codeSite, codeTable } = useRestaurantCodes()
    const { isLoading, restaurant } = useContext(RestaurantContext)

    // ⚠️ CornerCase: when restaurant not found for crashTaste feature
    // Usually the codeSite and codeTable are setted from QRCode scanning, in this case we dont scan directly
    if (!isLoading && restaurant === undefined && !matchPath(location.pathname, '/crash-taste')) {
        return <RestaurantNotFound />
    }

    const FoodRedirect = () => {
        return <Navigate replace to={`/${codeSite}/${codeTable}`} />
    }

    return (
        <Routes>
            <Route index element={<RestaurantNotFound />} />
            <Route path="crash-taste" element={<CrashTasteOutlet />}>
                <Route index element={<CrashTasteSelectRestaurant />} />
            </Route>
            <Route path="/food/:codeSite/:codeTable" element={<FoodRedirect />} />
            <Route path="/:codeSite/:codeTable">
                <Route index element={<Dashboard />} />
                <Route
                    path="menu-digital"
                    element={
                        <PrintModeProvider>
                            <MenuDigital />
                        </PrintModeProvider>
                    }
                />
                <Route path="crash-taste" element={<CrashTasteOutlet />}>
                    <Route index element={<CrashTaste />} />
                    <Route path="review" element={<CrashTasteReview />} />
                    <Route path="knowledge" element={<CrashTasteKnowledge />} />
                    <Route path="motivation" element={<CrashTasteMotivation />} />
                    <Route path="thank-you" element={<CrashTasteThankYou />} />
                </Route>
                <Route path="music-lab" element={<MusicLabOutlet />}>
                    <Route index element={<MusicLab />} />
                </Route>
                <Route path="blind-test" element={<BlindTestOutlet />}>
                    <Route index element={<BlindTest />} />
                    <Route path="join" element={<BlindTestJoin />} />
                    <Route path="register" element={<BlindTestRegister />} />
                </Route>
                <Route path="blind-test/tv" element={<BlindTestTv />} />
            </Route>
            <Route path="not-found" element={<PageNotFound />} />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    )
}

export default App
